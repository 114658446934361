<template>
    <div class="p-relative">
        <Toolbar
            :is-list="true"
            v-on:toolbar-button-click="onToolbarClick"
        >
            <SearchPanel
                v-model="search"
                :has-filter="extendedFilter != null"
                @on-filter-click="onFilterClick"
            />
        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="status"
        >
            <ExtendedFilterPanel
                v-if="$helpers.isExtendedFilterPanelVisible(extendedFilter)"
                v-bind:value.sync="extendedFilter"
            />
        </FilterPanel>

        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="entities"
            :item-class="itemRowBackground"
            :options.sync="options"
            :server-items-length="total"
            :items-per-page="10"
            :footer-props="{ itemsPerPageOptions:[10, 20, 30] }"
            :page.sync="options.page"
            @page-count="paginationPageCount = $event"
            class="elevation-1 customization-list-table pagination-list-table sticky-table"
            @dblclick:row="(event, data) => $eventBus.$emit('open-document', { id: data.item.id, type: 'Chancellery|Documents.Document', common: data.item.iscommon })"
            show-expand
            @item-expanded="itemExpanded"
            :expanded.sync="expanded"
        >
            <v-progress-linear slot="progress" height="2" color="#E43958" indeterminate></v-progress-linear>

            <template v-slot:[`item.data-table-expand`]="{ isExpanded, expand }">
                <v-btn 
                    icon
                    x-small
                    plain
                    class="expand-cust-btn"
                    @click="expand(true)" 
                    v-if="!isExpanded"
                >
                    <v-icon>
                        fas fa-caret-right
                    </v-icon>
                </v-btn>
                <v-btn 
                    icon
                    x-small
                    plain
                    class="expand-cust-btn"
                    @click="expand(false)" 
                    v-else
                >
                    <v-icon>
                        fas fa-caret-down
                    </v-icon>
                </v-btn>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <div class="table-expanded-wrapper">

                        <template v-if="item._info">
                            <v-treeview 
                                dense
                                :items="item._info"
                                class="cust-treeview-in-list"
                                :open.sync="open[item.id]"
                                >
                                <template v-slot:label="{ item }">

                                    <template v-if="item.text=='Link'">
                                            
                                        <div class="d-flex align-center">
                                            <span class="linked-docs-title">{{ $t(GetLinkedTypes[item.type]) }}</span>
                                            <div class="onlyReadData more-per-lab-wrap">
                                                <v-doc-chip
                                                    v-for="(doc, index) in item.Values"
                                                    :key="item.type+index"
                                                    :id="doc.values[6]"
                                                    :link-id="doc.values[0]"
                                                    :name="$t(GetDocumentTypes[doc.values[9]]) + ' № ' + doc.values[2] + ` ${$t('от_время')} ` + doc.values[3] + (doc.values[5] ? ' (' + doc.values[5] + ')' : '')" 
                                                    iconColor="#7289DA"
                                                    :is-linked-document="true"
                                                    :is-common-document="doc.values[8] == 'True'"
                                                    :source-document-id="item.sourceDocumentId"
                                                    :is-direct-link="parseInt(item.type) !== 9"
                                                />
                                            </div>
                                        </div>

                                    </template>
                                    <template v-else-if="item.text=='Notify'">
                                        <div class="d-flex ctil-row-wrap">
                                            <div class="flex-grow-1">
                                                <div class="elipsis-text-wrap">
                                                    <span>
                                                        {{ $t("Уведомление") }}
                                                    </span>
                                                    <span>
                                                        {{ item.Values[0] }}
                                                    </span>
                                                    <span>
                                                        <v-employee-chip :id="item.Values[3]" :name="item.Values[2]" /> 
                                                    </span>
                                                    <div class="etw-text-container" v-tooltip.top-center="item.Values[1]">
                                                        <span class="etw-text">
                                                            {{ item.Values[1] }}
                                                        </span>
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>                                           
                                    </template>
                                    <template v-else-if="item.text=='0'">
                                        <div class="d-flex ctil-row-wrap">
                                            <div class="d-flex align-center flex-grow-1">
                                                <div class="elipsis-text-wrap">
                                                    <span 
                                                        v-if="item.Values[14] == 'True'"
                                                        :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                    >
                                                        {{ $t("Резолюция") }}
                                                    </span>
                                                    <span 
                                                        v-else
                                                        :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                    >
                                                        {{ $t("Внешняя") }}
                                                    </span>
                                                    
                                                    <span :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''">
                                                        {{ item.Values[8] }}
                                                    </span>

                                                    <v-employee-chip v-if="item.Values[14] == 'True'" :id="item.Values[7]" :name="item.Values[1]" :is-employee="true" />
                                                    <v-enterprise-chip v-else :name="item.Values[1]" />

                                                    <i v-if="item.Values[13] === 'True'" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                                    <div class="etw-text-container" v-tooltip.top-center="item.Values[2]">
                                                        <span :class="[item.Values[5] == 7 ? 'text-decoration-line-through' : '', 'etw-text']">
                                                            {{ item.Values[2] }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                <span 
                                                    v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')"
                                                    :class="item.Values[5] == 7 ? 'text-decoration-line-through' : ''"
                                                >
                                                    {{ dateFormat(item.Values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                </span>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                <div class="onlyReadData more-per-lab-wrap"> 
                                                    <StatusChip
                                                        v-if="item.Values[14] == 'False' && item.hasExecutions"
                                                        :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1', 10))]"
                                                    />

                                                    <template v-else-if="item.Values[14] == 'True'">
                                                        <StatusChip
                                                            v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss') && getDaysDiff(item.Values[4]) > -1 && item.Values[5] == '5'"
                                                            :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                                                        />
                                                        <StatusChip
                                                            v-else
                                                            :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1'), 10)]"
                                                        />

                                                    </template>
                                                    
                                                    <StatusChip v-if="item.Values[19] || null" :status="avaibleStatuses[$options.rssStatusKeys[parseInt(item.Values[19] || '0')]]" />                    
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="item.text=='1'">
                                        <div class="d-flex ctil-row-wrap">
                                            <div class="d-flex align-center flex-grow-1">
                                                <div class="elipsis-text-wrap">
                                                    <span>{{$t("Исполнитель")}}</span>
                                                    <v-enterprise-chip
                                                        v-if="item.Values[9] === '00000000-0000-0000-0000-000000000000'"
                                                        :name="item.Values[1]"
                                                        :iconColor="executerStatusColor(item.Values[8])"
                                                        :main="item.Values[6] == 'True'"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :id="item.Values[9]"
                                                        :name="item.Values[0]"
                                                        :iconColor="executerStatusColor(item.Values[8])"
                                                        :main="item.Values[6] == 'True'"
                                                        :is-employee="true"
                                                    />

                                                    <div class="etw-text-container" v-tooltip.top-center="item.Values[5]">
                                                        <span class="etw-text">
                                                            {{ item.Values[5] }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                <span v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')">
                                                    {{ dateFormat(item.Values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                </span>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                <StatusChip :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[2] || '-1', 10))]" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="item.text=='2'">
                                        <div class="d-flex ctil-row-wrap">
                                            <div class="d-flex align-center flex-grow-1">
                                                <div class="elipsis-text-wrap">
                                                    <span>
                                                        {{ $t("Пункт") }} № {{ item.Values[15] }}
                                                    </span>
                                                    
                                                    <span>
                                                        {{ item.Values[8] }}
                                                    </span>

                                                    <v-employee-chip v-if="item.Values[14] == 'True'" :id="item.Values[7]" :name="item.Values[1]" :is-employee="true" />
                                                    <v-enterprise-chip v-else :name="item.Values[1]" />

                                                    <i v-if="item.Values[13] === 'True'" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                                    <div class="etw-text-container" v-tooltip.top-center="item.Values[2]">
                                                        <span class="etw-text">
                                                            {{ item.Values[2] }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                <span v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss')">
                                                    {{ dateFormat(item.Values[4], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                </span>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                <div class="onlyReadData more-per-lab-wrap"> 
                                                    <StatusChip
                                                        v-if="item.Values[14] == 'False' && item.hasExecutions"
                                                        :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1', 10))]"
                                                    />

                                                    <template v-else-if="item.Values[14] == 'True'">
                                                        <StatusChip
                                                            v-if="dateFormat(item.Values[4], 'YYYY', 'DD.MM.YYYY HH:mm:ss') && getDaysDiff(item.Values[4]) > -1 && item.Values[5] == '5'"
                                                            :status="avaibleStatuses[$helpers.getTaskStatus(1)]"
                                                        />
                                                        <StatusChip
                                                            v-else
                                                            :status="avaibleStatuses[$helpers.getTaskStatus(parseInt(item.Values[5] || '-1'), 10)]"
                                                        />

                                                    </template>
                                                    
                                                    <StatusChip v-if="item.Values[19] || null" :status="avaibleStatuses[$options.rssStatusKeys[parseInt(item.Values[19] || '0')]]" />                    
                                                </div>
                                            </div>
                                        </div>        
                                    </template>
                                    <template v-else-if="item.text=='3'">

                                        <div class="d-flex ctil-row-wrap">

                                            <div class="d-flex align-center flex-grow-1">
                                                <div class="elipsis-text-wrap">
                                                    <v-common-chip v-if="item.Values[8] === 'True'" document-type="IncomingDocument"/>
                                                    <span>
                                                        {{ $t("Пункт") }} № {{ item.Values[0] }} {{ $t("от_время") }} {{ dateFormat(item.Values[1], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }} &nbsp; - &nbsp; {{ item.Values[2] }}                                
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0" style="width:110px;">
                                                <span>
                                                    {{ dateFormat(item.Values[5], 'DD.MM.YYYY', 'DD.MM.YYYY HH:mm:ss') }}
                                                </span>
                                            </div>
                                            <div class="d-flex align-center flex-grow-0 status-dropdown-col" style="width:150px;">
                                                <div class="onlyReadData more-per-lab-wrap"> 
                                                    <StatusChip :status="avaibleStatuses[$helpers.calculateDocumentStatus(item.Values[4], dateFormat(item.Values[5], 'YYYY-MM-DD', 'DD.MM.YYYY'), dateFormat(item.Values[6], 'YYYY-MM-DD', 'DD.MM.YYYY'), item.Values[7] === 'True')]"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </template>
                                    <template v-else>
                                        {{ getLabel(item) }}
                                    </template>
                                    
                                </template>
                            </v-treeview>
                        </template>
                        <v-progress-linear v-else
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>

                    </div>
                </td>
            </template>

            <template v-slot:[`item.regdate`]="{ item }">
                <div>{{dateFormat(item.regdate, 'DD.MM.YYYY')}}</div>
            </template>

            <template v-slot:[`item.correspondent`]="{ item }">
                <div class="elipsis-text-wrap">
                    <v-enterprise-chip v-if="item.correspondent" :name="item.correspondent" />
                </div>
            </template>

            <template v-slot:[`item.theme`]="{ item }">
                <div class="elipsis-text-wrap">
                    <v-common-chip v-if="item.iscommon === true && item.isown === false" :document-type="item.doctypeof"/>                        
                    <v-doc-type-chip :type="item.doctypeof" :text="getDocType(item)"/>
                    <div class="etw-text-container">
                        <div class="etw-text" v-tooltip.top-center="item.theme">
                            {{item.theme}}
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:[`item.controldate`]="{ item }">
                <div>{{dateFormat(item.controldate, 'DD.MM.YYYY')}}</div>
            </template>

            <template v-slot:[`item.controlstatus`]="{ item }">
                <StatusChip :status="getStatus(item)"/>
            </template>

            <template v-slot:[`footer.prepend`]>
                <v-pagination
                    v-model="options.page"
                    :length="paginationPageCount"
                    :total-visible="$listPaginationTotal"
                    color="teal"
                ></v-pagination>
            </template>

        </v-data-table>

        <ProtocolExFilterDlg
            v-if="extendedFilter != null"
            v-model="extendedFilter" 
            ref="ProtocolExFilterDlgRef" 
        />

    </div>
</template>

<script>
import i18n from '@/i18n';
import { httpAPI } from "@/api/httpAPI";
import Axios from 'axios';
import _ from 'lodash';
import sys from '@/services/system';
import adapter from "@/services/adapter";
import { mapGetters, mapActions } from 'vuex';
import Toolbar from '@/components/documents/Toolbar.vue';
import SearchPanel from '@/components/saylau/SearchPanel.vue';
import FilterPanel from '@/components/documents/FilterPanel.vue';
import StatusChip from '@/components/global/chips/StatusChip';
import ExtendedFilterPanel from '@/components/documents/ExtendedFilterPanel.vue';
import ProtocolExFilterDlg from '@/components/orders/dialogs/extended-filters/protocol.vue';

export default {
    name: "OrdersList",
    components: {
        Toolbar,
        SearchPanel,
        StatusChip,
        FilterPanel,
        ExtendedFilterPanel,
        ProtocolExFilterDlg
    },
    columnIndex: {
        "regnumber" : 3,
        "regdate" : 4,
        "correspondent": 7,
        "theme": 5,
        "controldate": 6,
    },
    controlDocumentStates: {
        "All": -1,
        "NoControl": 0,
        "OnControl": 1,
        "Today": 2,
        "Expired": 3,
        "TakeOffControl": 4
    },
    computed: {
        ...mapGetters('documents/references', ['GetLinkedTypes', 'GetDocumentTypes']),
        collection() {
            switch (this.$store.getters['orders/getCurrentCollection']){
                case "Orders":
                default:
                    return "Protocols";
                case "ActItems":
                    return "ProtocolsActItems";
                case "OnExecutionList":
                    return "Protocol.OnExecution";
                case "OnControl":
                    return "Protocol.OnControl";
                case "OnAdjustmentList": 
                    return "Protocol.OnAdjustment";
                case "OnExecutionControl":
                    return "Protocol.OnExecutionControl";
            }
        },
        search: {
            get: function() {
                return this.$store.getters[`orders/lists/${this.storeName}/getSearch`];
            },
            set: _.debounce(function(v) {
                this.$store.commit(`orders/lists/${this.storeName}/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`orders/lists/${this.storeName}/SET_SEARCH`, v);
            }, 500)
        },
        status: {
            get: function() {
                return this.$store.getters[`orders/lists/${this.storeName}/getStatus`];
            },
            set: _.debounce(function(v) {
                this.$store.commit(`orders/lists/${this.storeName}/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`orders/lists/${this.storeName}/SET_STATUS`, v);
            }, 500)
        },
        extendedFilter: {
            get () {
                return this.$store.getters[`orders/lists/${this.storeName}/getExtendedFilter`];
            },
            set (newValue) {    
                this.$store.commit(`orders/lists/${this.storeName}/SET_TABLE_OPTION_PAGE`, 1);
                this.$store.commit(`orders/lists/${this.storeName}/SET_EXTENDED_FILTER`, newValue );
            }
        },
        options: {
            get: function() {
                return this.$store.getters[`orders/lists/${this.storeName}/getTableOptions`];
            },
            set: function(newValue) {
                this.$store.commit(`orders/lists/${this.storeName}/SET_TABLE_OPTION`, newValue);
            }
        },
        filter () {
            return {
                ...this.options,
                SearchStr: this.search,
                ControlDocumentState: this.$options.controlDocumentStates[this.status],
                extendedFilterData: this.$helpers.getOnlySelectedParams(this.extendedFilter)
            }
        },
        avaibleStatuses() {
            return this.$store.getters['documents/references/GetAvaibleStatuses'];
        },
        storeName() {
            return this.$route?.name?.toLowerCase();
        },
        headers() {
            let arr =  [
                { text: i18n.t("Рег._№"), value: 'regnumber', width: '150px' },
                { text: i18n.t("Рег._дата"), value: 'regdate', width: '110px' },
                { text: i18n.t("Корреспондент"), value: 'correspondent', width: '10%' },
                { text: i18n.t("Краткое_содержание"), value: 'theme'},
                { text: i18n.t("Срок"), value: 'controldate', width: '110px' },
            ]
            if (this.collectionIsOld || this.collection == "Protocol.OnExecutionControl" || this.collection == "Protocol.OnExecution")
                arr.push({ text: i18n.t("Статус"), value: 'controlstatus', width: '150px', sortable: false, class: 'status-col' });
            return arr;
        },
        collectionIsOld(){
            return ["Protocols","ProtocolsActItems"].includes(this.collection);
        }
    },
    data: () => {
        return {
            loading: true,
            cancellationTokenSorce: null,
            total: 0,            
            entities: [],
            prevTableFilterObject: null,
            paginationPageCount: 0,
            filterDataSource: null,
            expanded: [],
            open: {},
        }
    },
    methods: {
        ...mapActions('orders', ['updateCounter']),
        async itemExpanded(val) {

            if (!val.value)
                return;

            this.$set(val.item, '_info', null)

            let response = await httpAPI({
                url: `api/archive/get-document-children/${val.item.id}`,
                method: 'GET',               
                headers: { 'isCommon': val.item.iscommon }
            });

            let data = response?.data?.Payload?.Data?.Object?.data;

            if (data)
            {
                let treeData = adapter.transformTreeData(null, data, val.item.id);
                this.$set(val.item, '_info', treeData)
            }   
        },
        getDocType(item){
            if (this.collection != "Protocols"){
                if (item.clienttype == 1)
                    return `${item.doctype} (ИАС)`;
                return `${item.doctype} (СЭД)`;
            }
            return item.doctype;
        },
        getStatus(item){
            return this.avaibleStatuses[item.controlstatus] ?? item.controlstatus;
        },
        getLabel(item){
            return item.Values.toString();
        },
        resetPage() {
            this.$store.commit(`orders/lists/${this.storeName}/SET_TABLE_OPTION_PAGE`, 1);
        },
        itemRowBackground (item) {
            return item.readed ? '' : 'font-weight-bold';
        },
        onToolbarClick(event, btn){
            this[btn.Action](btn);
        },
        async Refresh() {
            await this.loadFilters();
            await this.getData(true);
        },
        async Export() {
            let filter = JSON.parse(this.getTableFilterObject());
            delete filter.Limit;
            delete filter.Offset;
            filter.collection = this.collection;
            let exportURI = `api/orders/export?param=${JSON.stringify(filter)}&mode=${process.env.NODE_ENV}`;
            exportURI += `&token=${this.$store.getters['global/auth/getUserInfo']?.Token}`;

            this.$eventBus.$emit('on-get-report', {url: exportURI, text: `Экспорт списка документов от ${this.$moment().format('DD.MM.YYYY HH.mm.ss')}`, type: 'Xls' });
        },
        async onFilterClick() {
            try
            {
                await this.$refs.ProtocolExFilterDlgRef.open();
            }
            catch (ex)
            {
                if (ex.isCancelled === true)
                    console.warn("Подготовка расширенного фильтра отменена");
            }
        },
        async getData(isRefresh = false) {
            if (isRefresh === false && this.$_.isEqual(this.prevTableFilterObject, this.getTableFilterObject()))
                return;

            this.prevTableFilterObject = this.getTableFilterObject();

            this.total = 0;
            this.entities = [];
            this.loading = true;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `/api/orders/list?collection=${this.collection}&filter=${this.getTableFilterObject()}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.entities = response?.data?.Payload?.data ?? [];
            this.total = response?.data?.Payload?.total ?? 0;

            this.loading = false;

            this.updateCounter();

        },
        getTableFilterObject() {
            let { sortBy, sortDesc, page, itemsPerPage, SearchStr, ControlDocumentState, extendedFilterData } = this.filter;
            let OrderDir = sortDesc.length <= 0 ? false : sortDesc[0];
            let sortColumn = sortBy.length <= 0 ? "regdate" : sortBy[0];
            let OrderBy = this.$options.columnIndex[sortColumn];
            let filter = {
                DocType: "Avrora.Objects.Modules.Docflow.DocflowObjects.ProtocolDocument",
                DocumentTypeC4: this.collection == "Protocols" ? "2" : "3",
                SearchStr,
                OrderBy,
                OrderDir,
                Limit: itemsPerPage,
                Offset: (page - 1) * itemsPerPage,
                ...extendedFilterData
            };
            if (this.collectionIsOld){
                filter.ControlDocumentState = ControlDocumentState;
            }
            return JSON.stringify(filter);
        },
        async loadFilters() {
            let filterResponse = null;
            if (this.collectionIsOld){
                filterResponse = await httpAPI({
                    url: `api/orders/filter?collection=${this.collection}`,
                    method: 'GET',
                });
            }

            this.filterDataSource = filterResponse ? filterResponse.data.Payload : null;
        },
        getDaysDiff(controlDate) {
            let diff = this.$moment(controlDate,'DD.MM.YYYY HH:mm:ss').diff(this.$moment(Date.now()), 'days');
            return diff;
        },
        executerStatusColor(item) {
            switch(item)
            {
                case 'success':
                    return "#43B581";
                case 'warning':
                    return "#FAA61A";
                case 'danger':
                    return "#F04747";
                default:
                    return null;
            }
        },
        async oneStatusColWidth() {
            var parent = document.getElementsByClassName('content-box')[0];
            var status_col_obj = parent.getElementsByClassName('status-col');
            var status_dropdown_col_obj = parent.getElementsByClassName('status-dropdown-col');
            var status_col_w = status_col_obj.length > 0 ? status_col_obj[0].clientWidth : 0;
            if(status_dropdown_col_obj.length > 0){
                for (var index = 0; index < status_dropdown_col_obj.length; index++) {
                    status_dropdown_col_obj[index].style.width = status_col_w + "px";
                }
            }
        },
        dateFormat: sys.dateFormat,
    },
    mounted() {
        this.$nextTick(this.oneStatusColWidth);
        window.addEventListener('resize', this.oneStatusColWidth);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.oneStatusColWidth);
    },
    watch: {
        filter: {
            handler () {
                this.getData()
            },
            deep: true,
        },
        collection:{
            handler () {
                this.loadFilters();
            }
        }
    },
    async created() {
        let response = await httpAPI({
            url: `api/actions/collection?type=Documents.Correspondence`,
            method: 'GET'
        });
        if (response) {
            this.$store.commit('documents/toolbar/SET_MENU', { menu: response.data.Payload.Data.Menu });
            this.loadFilters();
        }

    },
}
</script>